import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

// import "./blog.css"
import Blog from "../../components/blogs"
import Header from "../../components/header"
import Footer from "../../components/footer"
import Seo from "../../components/seo"
import Accesibilidad from "../../components/accesibilidad"
// import Layout from "../../../components/layout"

const Mapaweb = props => {
  const query = useStaticQuery(graphql`
    query Mapaweb {
      footer: allStrapiMenuFooter {
        edges {
          node {
            menu_item_footer {
              Titulo
              Perfiles
              sub_item_footer {
                Titulo
                Link_externo
                pagina {
                  title
                  slug
                  site
                }
              }
            }
          }
        }
      }

      allStrapiMainMenu {
        edges {
          node {
            menu_item {
              title
              site
              page {
                slug
              }
              sub_item {
                title
                page {
                  slug
                  title
                  site
                }
              }
            }
          }
        }
      }
    }
  `)

  const menu = query.allStrapiMainMenu.edges
  const footer = query.footer.edges
  const menu_footer = footer[0].node.menu_item_footer
  let url_global = process.env.GATSBY_API_URL
  let image_url =
    "url(" + url_global + "/uploads/" + "valla_03_6e3f99d9a6.jpg" + ")"

  return (
    <>
      <Accesibilidad />
      <Header site="personas" />
      <Seo title="Personas" />

      <div
        className="absolute w-screen altura_banner_blog ancho_banner_fondo_blog_sm altura_banner_quienes_somos lg:w-screen altura_banner_quienes_somos posicion_lado_banner_ventaja -top-0 bg-cover bg-right"
        title="valla mapa web"
        style={{
          backgroundImage: `${image_url}`,
        }}
      ></div>
      <div
        className="container mx-auto titulo_principal_blog_sm titulo_blog_encabezado_alinear text-poppins-bold lg:relative titulo_ventaja_lg"
        style={{
          width: "95%",
          left: "4rem",
          position: "relative",
          color: "#256238",
        }}
      >
        <p>
          Mapa <span className="green-secundary">del sitio</span>
        </p>
      </div>

      <div
        className="container mx-auto"
        style={{ marginTop: "24rem", marginBottom: "3rem" }}
      >
        {/* Para la funcionalidad */}
        <li className="">
          <a
            className="text-poppins-bold mitexto mitexto_color color-texto-parrafo"
            href={"/"}
          >
            Inicio
          </a>
        </li>

        <li>
          <Item item={menu[0]} key={0} />
        </li>
        <br />

        <p className="container mx-auto mitexto mitexto_color color-texto-parrafo text-poppins-bold">
          Pie de pagina
        </p>
        <br />

        {/* CODIGO HTML PARA FOOTER */}
        <div className="container mx-auto">
          {/* <div className="mitexto_color alineacion_contenido_footer w-full mx-auto flex flex-wrap"> */}
          <div className="inline-grid grid-cols-1 mitexto gap-3">
            <Itemfooter item={menu_footer} key={0} />
          </div>
        </div>
        {/* FIN DE CODIGO HTML PARA FOOTER */}
      </div>

      <Footer />
    </>
  )
}

function Item(payload) {
  const sites = ["personas", "empresas", "proveedores"]
  if (!payload || !payload.item.node || !payload.item.node.menu_item)
    return <></>

  const listItems = sites.map((site, i) => {
    return (
      <ol>
        <li className="text-poppins-bold mitexto mitexto_color color-texto-parrafo">
          {site}
        </li>
        <br />
        <SubMenuItem site={site} menu={payload} key={`s-${i}`} />
      </ol>
    )
  })
  return listItems
}

function SubMenuItem(payload) {
  if (
    !payload ||
    !payload.menu.item ||
    !payload.menu.item.node ||
    !payload.menu.item.node.menu_item
  )
    return <></>

  const menuSite = payload.menu.item?.node?.menu_item.filter(
    f => f.site === payload.site
  )

  const listItems = menuSite?.map((submenu, i) => {
    return (
      <>
        {submenu && submenu.page?.slug && (
          <li key={`m-${i}`}>
            <a
              className="text-poppins-bold mitexto mitexto_color color-texto-parrafo"
              href={"/" + payload.site + "/" + submenu?.page?.slug}
            >
              {submenu?.title}
            </a>
          </li>
        )}
        {submenu && submenu.sub_item.length > 0 && (
          <SubItem menu={submenu} key={`m-${i}`} />
        )}
      </>
    )
  })

  return listItems
}

function SubItem(payload) {
  if (!payload.menu || !payload.menu.sub_item) return <></>

  const listItems = payload.menu.sub_item.map((submenu, i) => (
    <li className="mr-3" key={`s-${i}`}>
      <a
        className="text-poppins-regular mitexto mitexto_color color-texto-parrafo"
        href={"/" + submenu?.page?.site + "/" + submenu?.page?.slug}
      >
        {submenu?.title}
      </a>
    </li>
  ))

  return (
    <ol className="pl-3">
      <li className="text-poppins-bold mitexto mitexto_color color-texto-parrafo">
        {payload.menu.title}
      </li>
      <ol className="pl-4">{listItems}</ol>
      <br />
    </ol>
  )
}

////////////////////////////////////////////////// CODIGO PARA PARA EL FOOTER /////////////////////////////////////////////////////////
function Itemfooter(payload) {
  if (!payload || !payload.length <= 0) return <></>

  const listItems = payload.item.map((node, i) => {
    return (
      <div>
        <ul key={i}>
          <li className="mitexto mitexto_color text-poppins-bold color-texto-parrafo">
            {node.Titulo}
          </li>

          <ul className="mitexto_color text-poppins-regular sub_menu_footer list-reset items-center pt-3 color-texto-parrafo pl-4">
            <SubItemFooter node={node} />
            <br />
          </ul>
        </ul>
      </div>
    )
  })

  return listItems
}

function SubItemFooter(payload) {
  if (!payload.node || !payload.node.sub_item_footer) return <></>

  const listItems = payload.node?.sub_item_footer.map((submenu, i) => (
    <li key={`s-${i}`}>
      {submenu?.pagina?.site === null && (
        <a
          className="mitexto_color text-poppins-regular color-texto-parrafo"
          href={`${
            submenu.Link_externo
              ? submenu.Link_externo
              : "/" + submenu?.pagina?.slug
          }`}
        >
          {submenu?.Titulo}
          <br></br>
          <br></br>
        </a>
      )}

      {submenu?.pagina?.site !== null && (
        <a
          className="mitexto_color text-poppins-regular color-texto-parrafo"
          href={`${
            submenu.Link_externo
              ? submenu.Link_externo
              : "/" + submenu?.pagina?.site + "/" + submenu?.pagina?.slug
          }`}
        >
          {submenu?.Titulo}
          <br></br>
          <br></br>
        </a>
      )}
    </li>
  ))

  return listItems
}

////////////////////////////////////////////////// FIN DEL CODIGO DEL FOOTER //////////////////////////////////////////////////////////

Mapaweb.propTypes = {
  siteTitle: PropTypes.string,
}

Mapaweb.defaultProps = {
  siteTitle: ``,
}

export default Mapaweb
